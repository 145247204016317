/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import PropTypes from "prop-types"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import "bootstrap/dist/css/bootstrap.css"
import { GlobalStyles } from "./Layout.styles"
import HowApexxCanHelpSection from "./HowApexxCanHelpSection/HowApexxCanHelpSection"
import { useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import loadable from "@loadable/component"
import CookieLogo from "./Logo/cookieLogo"
import FooterCallToAction from "../FooterCallToAction/FooterCallToAction";

const CookieNotice = loadable(() => import("@mirzalikic/react-cookie-notice"))

const Layout = ({
  children,
  headerTransparent,
  logoDark,
  whiteToggler,
  helpSectionColor,
  showHelpSection = true
}) => {
  const hashLink = typeof window !== `undefined` ? window.location.hash : null
  const [toggleVisibility, setToggleVisibility] = useState(false);
  useEffect(() => {
    hashLink && scrollTo(hashLink)
  }, [hashLink]);

  const displayFooterCta = showHelpSection ? <FooterCallToAction/> : '';

  return (
    <>
      <GlobalStyles
        headerTransparent={headerTransparent}
      />
      <Header
        headerTransparent={headerTransparent}
        logoDark={logoDark}
        whiteToggler = {whiteToggler}
      />
      <div id="cookie-popup-icon-container"
        onClick={() => {
              setToggleVisibility((toggleVisibility) => !toggleVisibility);
            }}
          >
        <div id="cookie-popup-icon">

        </div>
        <CookieLogo className="cookie-icon"/>
      </div>
      <main>{children}</main>
      {displayFooterCta}
      <CookieNotice
        onSave={(cookies) => {
          let enabledCookies = [];
            for (let i = 0; i < cookies.length; i++) {
              if (cookies[i].checked == true) {
                enabledCookies.push(cookies[i].name);
              }
            }
            if (enabledCookies.includes('analytics')) {
              let expiration_date = new Date();
              expiration_date.setFullYear(expiration_date.getFullYear() + 1);
              // Build the set-cookie string:
              document.cookie = "gatsby-gdpr-google-tagmanager=true; expires=" + expiration_date.toUTCString();
            }
            else{
              const cookiesList = ['gatsby-gdpr-google-tagmanager', 'NID', 'NDV', '1P_JAR', 'CONSENT', 'DSID', 'IDE', '_gid', '_ga', '_gat_UA-103173302-1', 'hubspotutk', '__hssc', '__hstc', '__hssrc', '_clck', '_clsk'];

              cookiesList.forEach((cookieItem) => {
                document.cookie = cookieItem + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
                document.cookie = cookieItem + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=" + window.location.hostname;
                document.cookie = cookieItem + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=." + window.location.hostname;
              });

              //the below is used to delete the _ga_ cookies
              let allCookies = document.cookie.split(";");
              for (let i = 0; i < allCookies.length; i++) {
                let cookie = allCookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie.trim();
            
                // If the cookie name starts with `_ga_`
                if (name.startsWith('_ga_') || name.startsWith(' _ga_')) {
                  // Delete the cookie
                  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
                  document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=" + window.location.hostname;
                  document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=." + window.location.hostname;
                }
              }
            }
            window.location.reload();
        }}
        onInit={(cookies) => {
            //console.log(cookies);
        }}
        acceptAllButtonText="Accept All Cookies"
        cookiePrefix="my-cookie-"
        cookies={[
            { name: 'necessary', checked: true, editable: false, default: true, title: 'Essential', text: 'Essential cookies enable basic functions and are necessary for the proper function of the website. The website cannot function properly without these cookies.'},
            { name: 'analytics', checked: false, editable: true, title: 'Analytics', text: 'We use these cookies to help us improve our site functionality by collecting and reporting information on its usage. This includes the use of Google Analytics.'},
        ]}
        containerStyle={{ background: 'rgb(20, 64, 86)', color: '#FFFFFF', borderTop: '1px solid rgba(255, 255, 255, 0.5)', overflowY: 'scroll', maxHeight: '100%'}}
        buttonSecondaryStyle={{background: 'rgb(31, 127, 195)', borderRadius: '15px', padding: '10px 30px', textTransform: "capitalize", fontSize: '16px', textDecoration: 'none', lineHeight: '1.5'}}
        buttonPrimaryStyle={{background: '#55baa3', color: '#FFFFFF', borderRadius: '15px', padding: '10px 30px', textTransform: "capitalize", fontSize: '16px', textDecoration: 'none', lineHeight: '1.5'}}
        contentStyle={{marginBottom: '.5rem'}}
        toggleVisibility={toggleVisibility}
        >
        <h3 style={{marginBottom: '1.5rem', marginTop: '1rem' }}>This website uses cookies</h3>
        <p style={{color: '#FFFFFF', marginBottom: '1.5rem'}}>We use cookies that help the website to function and also to track how you interact with it. We will only use the cookies if you consent to it by clicking on "Accept all cookies". You can also manage individual cookie preferences. To see more information about our use of cookies please view our <a href="/privacy-policy/#cookies-policy">privacy policy</a>.</p>
      </CookieNotice>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logoDark: PropTypes.bool,
}
Layout.defaultProps = {
  logoDark: true,
}
export default Layout
