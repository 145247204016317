import React from "react"

const Heartbeat = ({ }) => {
  return (
    <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56689 14.8445H14.0177C14.0375 14.8445 14.0554 14.8329 14.0634 14.8148L19.9852 1.49081C20.004 1.44863 20.065 1.45221 20.0787 1.49629L28.9734 30.1571C28.9881 30.2044 29.0553 30.2038 29.0692 30.1562L33.504 14.8806C33.5102 14.8592 33.5298 14.8445 33.552 14.8445H46.4969" stroke="url(#paint0_linear_10_47)" stroke-width="2.5" stroke-linecap="round"/>
      <defs>
      <linearGradient id="paint0_linear_10_47" x1="10.5" y1="30.3161" x2="10.5" y2="-2" gradientUnits="userSpaceOnUse">
      <stop stop-color="#41A4A0"/>
      <stop offset="1" stop-color="#2179B9"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Heartbeat;