import React, { useState, useEffect } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import Logo from "../Logo/Logo"
import { NavbarWrapper } from "./Header.styles"
import ApButton from "../../Common/ApButton/ApButton"
import { HEADER_DATA } from "../../../../data/header.data"
import HeaderItem from "./HeaderItem"
import { Link } from "gatsby"
import { LINKS } from "../../../../data/pageLinks.data"
// import useWindowSize from "../../Common/WindowSize/useWindowSize"
import { FiX, FiMenu } from "react-icons/fi"
import { useStaticQuery } from "gatsby";
import { getMenuData } from "../formatNavigationLinks"

const Header = ({
  headerTransparent,
  logoDark,
}) => {
  const { Brand, Collapse, Toggle } = Navbar;
  const [crossVisible, setCrossVisibility] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [breakpointCheck, setBreakpointCheck] = useState(false);

  let getStartedColor = 'greenPrimary';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setBreakpointCheck(false);
      }
      else {
        setBreakpointCheck(true)
      }
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerScrollClass = `
    ${headerTransparent && !scrolled ? (logoDark ? 'header-transparent' : 'header-transparent header-light') : ''}
  `.trim();

  const HEADER_LINKS = getMenuData(useGetHeaderLinks());

  return (
    <NavbarWrapper
      expand="xl"
      headerTransparent={headerTransparent}
      className={`navbar-light ${headerScrollClass} ${crossVisible ? "nav-expanded" : 'nav-condensed'}`}
      /* className={`${whiteToggler ? "navbar-dark" : null} ${headerMobile} ${crossVisible ? "nav-expanded" : 'nav-condensed'}`} */
      /* textlight={textlight} */
      id="site-navbar"
    >
      <Brand to={LINKS.index} as={Link} >
      {(headerTransparent && !logoDark && !scrolled && (breakpointCheck || !crossVisible)) ? (
        <Logo variant={"light"} />
      ) : (
        <Logo variant={"dark"} />
      )}
      </Brand>
      <Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => {
          setCrossVisibility(!crossVisible);
          setBreakpointCheck(false);
        }}
      >
        {crossVisible ? <FiX /> : <FiMenu />}
      </Toggle>
      <Collapse id="basic-navbar-nav">
        <Nav className="mx-auto d-flex justify-content-between">
          {HEADER_LINKS.map((item, idx) => (
            <HeaderItem item={item} key={idx} />
          ))}
        </Nav>
        <Link to={LINKS.contactUs}>
          <ApButton 
            className="border-0 mx-0 mx-xl-4 my-3 my-xl-0"
            variant={getStartedColor}
          >
            Get Started
          </ApButton>
        </Link>
      </Collapse>
    </NavbarWrapper>
  )
}

export const useGetHeaderLinks = () => {
  const headerLinks = useStaticQuery(graphql`
    {
      contentfulMenuItem(title: {eq: "Header Menu"}) {
        title
        subMenuItems {
          title
          pageReference {
            slug
          }
          url
          icon {
            description
            title
            fluid(quality: 10, maxWidth: 50) {
              src
            }
            file {
              url
            }
          }
          description {
            description
          }
          subMenuItems {
            title
            pageReference {
              slug
            }
            url
            icon {
              description
              title
              fluid(quality: 10, maxWidth: 50) {
                src
              }
              file {
                url
              }
            }
            description {
              description
            }
            subMenuItems {
              title
              pageReference {
                slug
              }
              url
              icon {
                description
                title
                fluid(quality: 10, maxWidth: 50) {
                  src
                }
                file {
                  url
                }
              }
              description {
                description
              }
            }
          }
        }
      }
    }
  `);

  return (headerLinks);
}

export default Header
