import styled from "styled-components";

export const FooterCallToActionWrapper = styled.section`

    background-color: ${props => props.theme.backgroundColour};

    .call-to-action-intro{
        display: flex;
        gap: 100px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        @media screen and (max-width: 1200px) {
            gap: 30px;
        }

        @media screen and (max-width: 991px) {
            justify-content: unset;
            align-items: unset;
            flex-direction: column;
            gap: 20px;
        }

        .buttons-container{
            display: flex;
            gap: 20px;

            @media screen and (max-width: 991px) {
                flex-wrap: wrap;
            }

            .btn{
                white-space: nowrap; 
            }
        }
    }

    .cta-panels{
        display: flex;
        gap: 32px;
        flex-wrap: nowrap;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            gap: 20px;
        }

        .panel{
            background-color: ${props => props.theme.panelColour};
            padding: 40px 35px;
            border-radius: 15px;
            flex: 33.333333%;

            .icon{
                height: 40px;
            }

            .title{
                font-size: 20px;
                line-height: 23px;
            }
        }
    }
`