import React from "react"

const Diamond = ({ }) => {
  return (
  <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5211 0.848877H10.1095L9.84033 1.16025L1.31913 11.0161L0.818848 11.5947L1.30927 12.1817L21.6369 36.5134L22.3276 37.3401L23.0183 36.5134L43.346 12.1817L43.8364 11.5947L43.3361 11.0161L34.8149 1.16025L34.5457 0.848877H34.1341H10.5211ZM3.96782 10.7047L10.3259 3.35083L12.9325 10.7047H3.96782ZM14.5014 9.74306L11.9868 2.64888H20.7373L14.5014 9.74306ZM16.0526 10.7047H28.6508L22.1443 3.77462L16.0526 10.7047ZM30.1145 9.63391L32.5969 2.64888H23.5564L30.1145 9.63391ZM31.6442 10.7047L34.2786 3.29209L40.6874 10.7047H31.6442ZM15.4803 12.5047H29.0942L22.2783 31.6832L15.4803 12.5047ZM3.92459 12.5047H13.5705L20.6791 32.5594L3.92459 12.5047ZM31.0045 12.5047L23.8039 32.7656L40.7307 12.5047H31.0045Z" fill="url(#paint0_linear_24_187)"/>
    <defs>
    <linearGradient id="paint0_linear_24_187" x1="21" y1="36.5" x2="21" y2="0.999999" gradientUnits="userSpaceOnUse">
    <stop stop-color="#41A4A0"/>
    <stop offset="1" stop-color="#2179B9"/>
    </linearGradient>
    </defs>
  </svg>
  )
}

export default Diamond
