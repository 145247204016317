import { LINKS } from "./pageLinks.data"

export const HEADER_DATA = [
  {
    title: "Our Solution",
    items: [
      {
        title: "Product",
        imageVariant: "product",
        links: [
          {
            title: "Payment Orchestration Platform",
            description: "Optimise your online payments set-up",
            url: LINKS.gateway,
          },
          {
            title: "APEXX: Connect",
            description: "Aggregation tool for Buy-Now-Pay-Later solutions",
            url: LINKS.bnplAggregator,
          },

          {
            title: "Analytics",
            description:
              "The ultimate dashboard across your entire payments stack",
            url: LINKS.reporting,
          },
        ],
      },
      {
        title: "Features",
        imageVariant: "features",
        links: [
          {
            title: "APM’s",
            description: "Learn about local payment methods",
            url: LINKS.apm,
          },
          {
            title: "Dynamic Routing",
            description:
              "Route your transactions to the most beneficial provider",
            url: LINKS.dynamicRouting,
          },
          // {
          //   title: "Local Processing",
          //   description:
          //     "Process locally for lower cost and increased acceptance",
          //   url: "#",
          // },
        ],
      },
    ],
  },
  {
    title: "Developers",
    items: [
      {
        links: [
          {
            title: "API",
            description: "Explore the APEXX Gateway API",
            url: LINKS.api,
            imageVariant: "api",
            target: "_blank"
          },
        ],
      },
    ],
  },
  {
    title: "Knowledge Hub",
    items: [
      {
        links: [
          {
            // title: "Press Room",
            title: "Payment Processing Cost Saving Calculator",
            description: "Find out how much extra your business is paying via the cost saving calculator",
            url: LINKS.costSavingCalculator,
          },
          {
            title: "Country Reports",
            description: "Learn about payments across the globe",
            url: LINKS.countryReports,
          },
          {
            title: "Blog",
            description:
              "Learn best practice from across the payments industry",
            url: LINKS.blog,
          },
          // {
          //   title: "News",
          //   description: "The latest news from the APEXX universe",
          //   url: "#",
          //   imageVariant: "news",
          // },
          {
            // title: "Press Room",
            title: "News",
            description: "Download an APEXX Global press kit",
            url: LINKS.pressAndMedia,
          },
          {
            // title: "Press Room",
            title: "Case Studies",
            description: "How are APEXX helping our merchants?",
            url: LINKS.caseStudies,
          },
        ],
      },
    ],
  },
  {
    title: "Support",
    url: LINKS.support,
    target: "_blank"
  },
  {
    title: "Company",
    items: [
      {
        links: [
          {
            title: "Our Story",
            description: "Learn about the APEXX journey",
            url: LINKS.ourStory,
            //imageVariant: "apexx",
          },
          {
            title: "Partnerships",
            description: "Become an APEXX Partner",
            url: LINKS.partnerships,
            //imageVariant: "partnerships",
          },
          {
            title: "About APEXX",
            description: "Why not join the APEXX team?",
            url: LINKS.careers,
            //imageVariant: "careers",
          },
        ],
      },
    ],
  },
]
