import React from "react";
import { Container } from "react-bootstrap";
import ApButton from "../Common/ApButton/ApButton";
import { FooterCallToActionWrapper } from "./FooterCallToAction.styles";
import Diamond from "./Icons/diamond";
import Graph from "./Icons/graph";
import Heartbeat from "./Icons/heartbeat";
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"
import { ThemeProvider } from 'styled-components';

const FooterCallToAction = () => {

    const theme = {
        backgroundColour: '#FFFFFF',
        panelColour: '#F4F9FD'
    }

    return(
        <ThemeProvider theme={theme}>
            <FooterCallToActionWrapper className="py-5 px-3">
                <Container className="my-3 my-lg-5">
                    <div className="call-to-action-intro mb-5 mb-lg-4">
                        <h2 class="primary-blue-text ambit font-weight-bold">The APEXX Payment Orchestration Platform is the last integration you will ever need</h2>
                        <div className="buttons-container">
                            <ApButton
                                className="font-weight-bold btn"
                                variant="greenTwoLarge"
                                as={Link}
                                to={LINKS.contactUs}
                            >
                                Contact Sales
                            </ApButton>
                            <ApButton
                                className="font-weight-bold btn"
                                variant="lightGreenLarge"
                                as={Link}
                                to={LINKS.gateway}
                            >
                                See how it works
                            </ApButton>     
                        </div>
                    </div>
                    <div className="cta-panels">
                        <div className="panel">
                            <div className="icon mb-3">
                                <Diamond/>
                            </div>
                            <div className="title text-black ambit font-weight-bold mb-3">Lowest cost</div>
                            <div className="cta-copy poppins">
                                We structure ourselves to operate in the most efficient way. <strong>We are not an additional cost as we take the place of the payment gateway.</strong>
                            </div>
                        </div>
                        <div className="panel">
                            <div className="icon mb-3">
                                <Graph/>
                            </div>
                            <div className="title text-black ambit font-weight-bold mb-3">Lead in Orchestration</div>
                            <div className="cta-copy poppins">
                                We enhance the payment experience by driving up acceptance rates thereby reducing friction and lost sales for our Merchants.
                            </div>
                        </div>
                        <div className="panel">
                            <div className="icon mb-3">
                                <Heartbeat/>
                            </div>
                            <div className="title text-black ambit font-weight-bold mb-3">Excellent Customer Support</div>
                            <div className="cta-copy poppins">
                                The APEXX Payment Orchestration Platform is architected to the highest industry standards of security and support ensuring that our merchants have the best protection and support access at all times.
                            </div>
                        </div>
                    </div>
                </Container>
            </FooterCallToActionWrapper>
        </ThemeProvider>
    );
};

export default FooterCallToAction;