import styled, { css } from "styled-components";
import { animateCss } from "./utils/animate.styles";


// Define the font sizes and line heights with variants
const headingSizes = {
    h1: {desktop: ['64px', '1.2em'], tablet: ['48px','1.2em'], mobile: ['40px','1.2em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    "h1.title": {desktop: ['48px', '1.2em'], tablet: ['40px','1.2em'], mobile: ['40px','1.2em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    h2: {desktop: ['48px', '1.2em'], tablet: ['40px','1.2em'], mobile: ['32px','1.2em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    h3: {desktop: ['40px', '1.2em'], tablet: ['32px','1.2em'], mobile: ['24px','1.2em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    h4: {desktop: ['32px', '1.2em'], tablet: ['24px','1.2em'], mobile: ['24px','1.2em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    h5: {desktop: ['20px', '1.5em'], tablet: ['20px','1.5em'], mobile: ['20px','1.5em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    h6: {desktop: ['18px', '1.5em'], tablet: ['18px','1.5em'], mobile: ['18px','1.5em'], fontFamily: 'Ambit', fontWeight: 'Bold'},
    p: {desktop: ['16px', '1.5em'], tablet: ['16px','1.5em'], mobile: ['16px','1.5em'], fontFamily: 'Poppins', fontWeight: 'normal'},
    "general": {desktop: ['16px', '1.5em'], tablet: ['16px','1.5em'], mobile: ['16px','1.5em'], fontFamily: 'Poppins', fontWeight: 'normal'},
    "p.small": {desktop: ['13px', '1.5em'], tablet: ['13px','1.5em'], mobile: ['13px','1.5em'], fontFamily: 'Poppins', fontWeight: 'normal'},
};

// Helper function to get font size and line-height based on variant
function getHeadingStyles() {
    let styles = '';
    const getFontSizes = headingSizes;
    delete getFontSizes.general;
  
    // Loop through each heading (h1, h2) and generate styles dynamically
    Object.entries(getFontSizes).forEach(([heading, sizes]) => {
      styles += `
        ${heading} {
          font-size: ${sizes.desktop[0]};
          line-height: ${sizes.desktop[1]};
          font-family: ${sizes.fontFamily};
          font-weight: ${sizes.fontWeight};
    
          @media (max-width: ${FlexipageColors.breakpointTablet}) {
            font-size: ${sizes.tablet[0]};
            line-height: ${sizes.tablet[1]};
          }
    
          @media (max-width: ${FlexipageColors.breakpointMobile}) {
            font-size: ${sizes.mobile[0]};
            line-height: ${sizes.mobile[1]};
          }
        }
      `;
    });
  
    return css`${styles}`;
}

export const FlexipageColors = {

    blue1200: `#052231`,
    blue1000: `#144056`,
    blue900: `#124997`,
    blue800: `#385DA7`,
    blue700: `#0F6CB6`,
    blue600: `#1E7FC2`,
    blue500: `#428AC9`,
    blue400: `#4CB9EF`,
    blue200: `#8FC5EA`,
    blue100: `#F4F9FD`,

    teal1200: `#15725A`,
    teal1000: `#46AC92`,
    teal800: `#55BAA3`,
    teal600: `#55B9A3`,
    teal400: `#39B8BD`,
    teal300: `#6BE2E5`,
    teal200: `#6ECDD6`,

    grey1000: `#262626`,
    grey800: `#505050`,
    grey600: `#7A7A7A`,
    grey400: `#B0B0B0`,
    grey200: `#C6C6C6`,
    grey150: `#DFDFDF`,
    grey100: `#f7f7f7`,
    white: `#FFFFFF`,

    buttonBlue: `#144056`,
    buttonTeal: `#46AC92`,
    secondaryButtonBlue: `#8FC5EA`,
    secondaryButtonTeal: `#6ECDD6`,
    
    gradientDark: `linear-gradient(180deg, #144056 0%, #428AC9 100%)`,
    gradientMid: `linear-gradient(180deg, #3B5CA7 0%, #428AC9 100%);`,
    gradientLight: `linear-gradient(180deg, #d5e0fa 0%, #cceaff 100%);`,
    gradientNeutral: `linear-gradient(180deg, #ddeef9 0%, #d4fdf4 100%);`,
    gradientExtraLight: `linear-gradient(180deg, #ddf1ed 0%, #e9f4fb 100%);`,

    breakpointTablet: `992px`,
    breakpointMobile: `767px`,
}

const FlexipageDefaultCssVariables = css`
  --color--primary: ${FlexipageColors.blue1000};
  --color--secondary: ${FlexipageColors.teal1000};
  --color--white: ${FlexipageColors.white};
  --color--transparent--dark: rgba(0, 0, 0, 0.25);
  --color--transparent--light: rgba(255, 255 ,255, 0.25);

  //generic styles
  --color--paragraph: var(--color--primary);
  --color--headings: var(--color--secondary);
  --color--headings--alt: var(--color--primary);
  --color--link: var(--color--secondary);

  --color--button--background: var(--color--primary);
  --color--button--text: var(--color--white);
  --color--button--background--hover: ${FlexipageColors.blue1200};
  --color--button--outline--focus: ${FlexipageColors.blue200};
  --color--button--large--background: var(--color--secondary);
  --color--button--large--background--hover: ${FlexipageColors.teal1200};
  --color--button--small--background: var(--color--secondary);
  --color--button--small--background--hover: ${FlexipageColors.teal1200};
  --color--list-item--marker: var(--color--secondary);
  --color--blockquote--background: #E5E5E5;
  --color--blockquote--border: var(--color--secondary);
  --color--blockquote--text: var(--color--primary);

  //table styles
  --color--th--background: var(--color--primary);
  --color--th--text: var(--color--white);
  --color--td--background: ${FlexipageColors.blue100};
  --color--td--text: var(--color--primary);
  --color--tr--border: #D9D9D9;

  //tabbed interface
  --color--tabbed--container--background: var(--color--blockquote--background);
  --color--tabbed--container-text: var(--color--blockquote--text);

  --breakpoint--tablet: ${FlexipageColors.breakpointTablet};
  --breakpoint--mobile: ${FlexipageColors.breakpointMobile};

  //two column
  --color--subtitle--circle: rgba(0,0,0,0.05);
  --color--subtitle--line: var(--color--headings--alt);

  //accordion
  --color--accordion--summary--background: var(--color--primary);
  --color--accordion--summary--text: var(--color--white);
  --color--accordion--description--background: var(--color--blockquote--background);
  --color--accordion--description--text: var(--color--blockquote--text);
  --color--accordion--description--link: var(--color--accordion--description--text);
  --color--accordion--toggle: var(--color--accordion--summary--text);

  --color--hero-cta--panel--background: linear-gradient(180deg,#ddf1ed 0%,#e9f4fb 100%);
  --color--hero-cta--panel--text: var(--color--primary);
`

const FlexipageCssVariables = css`
  ${FlexipageDefaultCssVariables}

  .bg-gradient-dark, &.bg-gradient-dark{
    background: ${FlexipageColors.gradientDark};
    --color--paragraph: var(--color--white);
    --color--headings: ${FlexipageColors.teal800};
    --color--headings--alt: ${FlexipageColors.blue100};
    --color--link: var(--color--white);

    --color--button--background: var(--color--secondary);
    --color--button--background--hover: ${FlexipageColors.teal1200};
    
    --color--blockquote--background: var(--color--transparent--dark);
    --color--blockquote--text: var(--color--white);

    --color--th--background: var(--color--secondary);
    --color--tr--border: ${FlexipageColors.teal1200};
    --color--subtitle--circle: rgba(255,255,255,.075);
    --color--subtitle--line: var(--color--secondary);
  }

  .bg-gradient-mid, &.bg-gradient-mid{
    background: ${FlexipageColors.gradientMid};

    --color--paragraph: var(--color--white);
    --color--headings: var(--color--white);
    --color--headings--alt: var(--color--secondary);
    --color--link: var(--color--white);

    --color--blockquote--background: var(--color--transparent--dark);
    --color--blockquote--text: var(--color--white);

    --color--accordion--summary--background: var(--color--white);
    --color--accordion--summary--text: var(--color--primary);

    --color--subtitle--circle: rgba(255,255,255,.075);
  }

  .bg-gradient-light, &.bg-gradient-light{
    background: ${FlexipageColors.gradientLight};

    --color--headings: var(--color--primary);
    --color--headings--alt: var(--color--secondary);
    --color--link: var(--color--secondary);
    
    --color--blockquote--background: var(--color--white);
    --color--blockquote--border: var(--color--secondary);
    
    --color--hero-cta--panel--background: var(--color--white);
  }

  .bg-gradient-neutral, &.bg-gradient-neutral{
    background: ${FlexipageColors.gradientNeutral};

    --color--headings: var(--color--primary);
    --color--headings--alt: var(--color--secondary);
    --color--blockquote--background: var(--color--secondary);
    --color--blockquote--border: var(--color--primary);
    --color--blockquote--text: var(--color--white);
    --color--hero-cta--panel--background: var(--color--white);
  }

  .bg-gradient-extra-light, &.bg-gradient-extra-light{
    background: ${FlexipageColors.gradientExtraLight};

    --color--headings: var(--color--primary);
    --color--headings--alt: var(--color--secondary);
    
    --color--blockquote--background: var(--color--white);
    --color--blockquote--border: var(--color--secondary);

    --color--hero-cta--panel--background: var(--color--white);
  }

  .bg-dark-blue, &.bg-dark-blue{
    background: ${FlexipageColors.blue1000};
    --color--paragraph: var(--color--white);
    --color--headings: var(--color--white);
    --color--headings--alt: ${FlexipageColors.teal300};
    --color--link: var(--color--white);

    --color--button--background: var(--color--secondary);
    --color--button--background--hover: ${FlexipageColors.teal1200};

    --color--blockquote--background: var(--color--transparent--dark);
    --color--blockquote--text: var(--color--white);

    --color--th--background: var(--color--secondary);
    --color--tr--border: ${FlexipageColors.teal1200};

    --color--subtitle--circle: rgba(255,255,255,.075);

    --color--accordion--summary--background: var(--color--white);
    --color--accordion--summary--text: var(--color--primary);
  }
  .bg-white, &.bg-white{
    ${FlexipageDefaultCssVariables}
    background-color: var(--color--white);
  }

  .bg-light-grey, &.bg-light-grey{
    ${FlexipageDefaultCssVariables}
    background: ${FlexipageColors.grey100};
    --color--blockquote--background: rgba(0, 0, 0, 0.15);
    --color--td--background: var(--color--white);
    --color--tr--border: ${FlexipageColors.grey200};
  }
`

export const contentStyles = css`
  ${FlexipageCssVariables}

  font-size: ${headingSizes["general"].desktop[0]};
  line-height: ${headingSizes["general"].desktop[1]};
  font-family: ${headingSizes["general"].fontFamily};
  font-weight: ${headingSizes["general"].fontWeight};

  @media (max-width: var(--breakpoint--tablet)) {
    font-size: ${headingSizes["general"].tablet[0]};
    line-height: ${headingSizes["general"].tablet[1]};
  }

  @media (max-width: var(--breakpoint--mobile)) {
    font-size: ${headingSizes["general"].mobile[0]};
    line-height: ${headingSizes["general"].mobile[1]};
  }

  ${getHeadingStyles}

  h1, h2, h3, h4, h5, h6{
    color: var(--color--headings);
    margin-bottom: 2.25rem;

    .alt, &.alt{
      color: var(--color--headings--alt);
    }
  }

  a{
    color: var(--color--link);
    text-decoration: underline;

    &:hover{
      text-decoration: none;
      opacity: 0.7;
    }
  }

  p{
    color: var(--color--paragraph);
    margin-bottom: 1.5em;
  }

  img{
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }

  ul, ol{
    padding-left: 35px;
    li{
      position: relative;
      margin-bottom: 1em;
      color: var(--color--paragraph);
    }
  }
  
  &.text-center {
    ul, ol{
      padding-left: 0;
      list-style-position: inside;
      
      p{
        display: inline-block;
      }
    }
    
    ul{
      li{
        &::before{
          position: relative;
          left: unset;
          top: 2px;
          margin-right: 8px;
        }
      }
    }
  }

  ul{
    li{
      list-style: none;

      &::before{
        content: "";
        position: absolute;
        top: 7px;
        left: -34px;
        width: 13px;
        height: 13px;
        display: inline-block;
        background-color: var(--color--list-item--marker);
        border-radius: 8px;
      }
    }
  }

  ol{
    list-style-position: outside;
    padding-left: 18px;
    li{
      padding-left: 17px;
      &::marker{
        color: var(--color--list-item--marker);
        font-weight: bold;
      }
    }
  }

  ul, ol{
    margin-bottom: 2.5em;

    li{
      margin-bottom: 1.25em;
    }
  }

  button, a.button{
    display: inline-block;
    background-color: var(--color--button--background);
    color: var(--color--button--text)!important;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    padding: 9px 30px 8px;
    margin: 0 15px;
    font-weight: bold;
    font-size: 16px;
    transition: background 0.2s linear;

    + button, + a.button{
      margin-left: 0;
    }

    &:hover{
      opacity: 1;
      background-color: var(--color--button--background--hover);
    }
    &:focus-visible{
      background-color: var(--color--button--background);
      outline: 3px solid var(--color--button--outline--focus);
    }

    &.large{
      --color--button--background: var(--color--button--large--background);
      --color--button--background--hover: var(--color--button--large--background--hover);
      font-size: 20px;
      font-weight: normal;
      padding: 12px 40px 13px;
    }
    &.small{
      --color--button--background: var(--color--button--small--background);
      --color--button--background--hover: var(--color--button--small--background--hover);
      font-size: 16px;
      font-weight: normal;
      padding: 9px 25px 8px;
      border-radius: 25px;
    }
  }

  blockquote{
    background: var(--color--blockquote--background);
    border-left: 6px solid var(--color--blockquote--border);
    color: var(--color--blockquote--text);
    margin: 0;
    padding: 20px 30px 20px 30px;
    position: relative;
    text-align: left;
    margin: 1.5em 0;

    @media screen and (max-width: ${FlexipageColors.breakpointMobile}){
      padding: 30px;
    }

    p, a{
      color: var(--color--blockquote--text);
    }

    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  hr{
    border-top: 1px solid var(--color--paragraph);
    margin-top: 3em;
    margin-bottom: 3em;
  }

  table{
    width: 100%;
    max-width: 1300px;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden; /* To make sure the rounded corners are visible */

    td, th{
      padding: 18px;
    }

    th{
      background-color: var(--color--th--background);
      color: var(--color--th--text);
      *{
        color: var(--color--th--text);
      }
    }
    
    td{
        background-color: var(--color--td--background);
        color: var(--color--td--text);
        *{
          color: var(--color--td--text);
        }
    }

    tbody{
      tr{
        border-top: 1px solid var(--color--tr--border);

        &:last-child{
          border-bottom: unset;
        }
      }
      
    }

    &.responsive{
      border-radius: 0;

        thead{
            display: none !important;
            height: 0 !important;
            overflow: hidden !important;

            th{
                max-width: 100%;
                min-width: 280px;
            }
        }

        tbody{
            tr{
                display: block;
                max-width: 100%;
                margin-bottom: 10px;
                border-bottom: unset;
                border-radius: 10px;
                overflow: hidden;

                th, td{
                  display: block;
                }
            }

            td{
                display: block;
                padding: 20px;
                border-bottom: 1px;

                &:nth-child(even){
                  
                }

                &:last-child{
                    margin-bottom: 0px;
                }

                &:before{
                    content: attr(data-title);
                    display: block;
                    margin-bottom: 10px;
                    font-weight: bold;
                    white-space: pre;
                }
            }
        }
    }
  }

  .flexipage-container{
    max-width: 1170px;
    margin: auto;
    width: 100%;
  }

  .gc-video-container{
    margin: 3em 0;
  }
`

export const GenericContentStyles = styled.section`
  ${contentStyles}
`

export const FlexipageWrapper = styled.div`
  ${animateCss};

  [data-animation]{
		opacity: 0;

		@media print{
			opacity: unset;
		}

		&.animate__animated{
			opacity: 1;
		}
	}
  section.flexipage-row{
    padding: 6rem 20px;
    scroll-margin-top: 86px;

    @media (max-width: ${FlexipageColors.breakpointTablet}) {
      padding: 4rem 20px;
    }
    @media (max-width: ${FlexipageColors.breakpointMobile}) {
      padding: 3rem 20px;
    }

    &.widget-row{
        padding: 0;

        @media (max-width: ${FlexipageColors.breakpointTablet}) {
            padding: 0;
        }
        @media (max-width: ${FlexipageColors.breakpointMobile}) {
            padding: 0;
        }
    }

    &.prev-bg-white:not(.widget-logos-map) + .bg-white,
    &.prev-bg-dark-blue + .bg-dark-blue,
    &.prev-bg-light-grey + .bg-light-grey{
      @media (max-width: 991px) {
          padding-top: 0;
      }
    }
    
    &.bg-white + section.flexipage-row.bg-white,
    &.bg-dark-blue + section.flexipage-row.bg-dark-blue,
    &.bg-light-grey + section.flexipage-row.bg-light-grey,
    &:first-child.bg-white{
      padding-top: 0;
      scroll-margin-top: 120px;
    }

    &:last-child.bg-white, &.bg-white.widget-row-next-logos-map{
      padding-bottom: 0;
    }
  }
`