import React from "react"
import { NavDropdown, Nav } from "react-bootstrap"
import HeaderDropdownItem from "./HeaderDropdownItem"
import SEOHiddenNavDropdownItems from "./SEOHiddenNavDropdownItems"

const HeaderItem = ({ item }) => {
  const { title, items, url } = item
  if (!items)
    return (
      <Nav.Link href={url} className="nav-item" target="_blank">
        {title}
      </Nav.Link>
    )
    let navClass = "basic-nav-dropdown";
    if (items.length >= 2) {
      navClass += ' large-sub-menu';
    }
  return (
    <>
    <SEOHiddenNavDropdownItems items={items}/>
    <NavDropdown title={title} className={navClass}>
      <div className="d-block d-xl-flex dropdown-container">
        {items.map((item, idx) => (
          <HeaderDropdownItem item={item} key={idx} />
        ))}
      </div>
    </NavDropdown>
    </>
  )
}

export default HeaderItem
