import React from "react"

function SEOHiddenNavDropdownItems({ items }) {

  return (
    <ul
      aria-hidden="true"
      style={{ height: "0px", width: "0px", visibility: "hidden", zIndex: "-1000", margin: "0px", padding: "0px" }}
    >
      {items.map((dropdownColumns) => (
        <li key={dropdownColumns.title} style={{ height: "0px", width: "0px", visibility: "hidden", zIndex: "-1000", margin: "0px", padding: "0px" }}>
          <p>{dropdownColumns.title}</p>
          <ul style={{ height: "0px", width: "0px", visibility: "hidden", zIndex: "-1000", margin: "0px", padding: "0px" }}>
            {dropdownColumns.links.map(({ title, url }) => (
              <li key={url} style={{ height: "0px", width: "0px", visibility: "hidden", zIndex: "-1000", margin: "0px", padding: "0px" }}>
                <a href={url}>{title}</a>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default SEOHiddenNavDropdownItems
