import styled from "styled-components"
import { FlexipageColors } from "../../../Flexipage/Global/FlexipageStyles"
import Navbar from "react-bootstrap/Navbar"

import { colors, fonts } from "../../../../utils/styles"

const { primaryBlue, secondaryBlue2 } = colors

export const NavbarWrapper = styled(Navbar)`

  z-index: 9999;
  width: 100vw;
  min-width: 320px;
  position: fixed;
  background-color: #FFFFFF;
  transition: background 0.3s;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

  &.header-transparent{
    background-color: transparent;
    box-shadow: unset;

    &.header-light{
      &.nav-condensed{
        .navbar-toggler{
          color: #FFF;
        }
      }

      @media (min-width: 1200px) {
        a{
          color: #FFFFFF !important;
        }
      }
    }
    @media (max-width: 1199px) {
      &.nav-expanded{
        background-color: #FFFFFF;
        transition: background 0.5s;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
      }
    }
  }

/*  &.blueGradient{
    @media (max-width: 992px) {
      background-color: transparent;
      background: linear-gradient(90deg,#84E7F0 0%,#78AAEE 100%),#144056;
      background-blend-mode: overlay,normal;
    }
  }
  &.ghostWhite{
    @media (max-width: 992px) {
      background-color: #F7F9FC;
    }
    .navbar-toggler{
      color: ${secondaryBlue2};
    }
  }
  &.bgWhite{
    @media (max-width: 992px) {
      background-color: #FFFFFF;
    }
    .navbar-toggler{
      color: ${secondaryBlue2};
    }
  }
   &.stay-absolute{
    //position: absolute;
    &.toggler-green{
      .navbar-toggler{
        color: ${secondaryBlue2};
      }
    }
    &.nav-condensed{
      background-color: transparent;
    }

    &.secondPrimary{
      @media (max-width: 1199px) {
        background-color: #144056;
      }
      .navbar-toggler{
        color: #FFF;
      }
      &.nav-condensed:not(:has(.collapsing)){
        background-color: transparent;
      }
    }
  } */

  .navbar-collapse{
    max-width: 100%;
  }

  .dropdown-menu {
    border: none;
    padding: 0;
    overflow: hidden;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

    @media (max-width: 1199px) {
      padding: 0.5rem;
    }
  }
  a {
    font-weight: 600;
    //color: ${(props) => (props.textlight ? "#ffffff" : primaryBlue)} !important;
    color: ${primaryBlue} !important;
  }
  .nav-item {
    @media (min-width: 1200px) {
      margin-right: 60px;

      &:last-child{
        margin-right: 0;
      }
    }

    @media (max-width: 1199px) {
      .nav-link{
        white-space: normal;
      }
    }    
  }
  ul[aria-hidden="true"]{
    &, li, a{
      display: block;
      height: 0px;
      width: 0px;
      visibility: hidden;
      z-index: -1000;
      margin: 0px;
      padding: 0px;
    }
    &::marker{
      display: none;
    }
  }
  .row.dropdown-heading-container{
    margin-bottom: 15px;
    @media (min-width: 1200px) {
      width: 360px;
    }
  }
  .row.dropdown-item-heading {
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    color: ${FlexipageColors.white};
    background-color: ${FlexipageColors.blue1000};
    @media (min-width: 1200px) {
      text-align: center;
    }
    
    svg {
      width: 16px;
      margin-right: 5px;
    }
  }
  .dropdown-item {
    white-space: normal;
    padding: 0.25rem 1rem;

    &:first-child{
      margin-top: 15px;
    }

    &.active,
    &:active {
      background-color: transparent !important;
    }
    p {
      color: ${secondaryBlue2};
    }
    span {
      color: #3f4041;
      font-family: ${fonts.poppins};
      font-weight: 100;
      font-size: x-small;
    }
  }
  .dropdown-toggle {
    &:after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${secondaryBlue2};
      border-width: 0px 1px 1px 0px;
      transform: rotate(45deg);
      transition: border-width 150ms ease-in-out;
      margin-left: 10px;
    }
  }
  .navbar-toggler {
    border: 0px;
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  .basic-nav-dropdown {
    position: relative;
    a[aria-expanded="true"]::after {
      transform: translateY(4px) rotate(-135deg);
      transition: border-with 0s;
    }
    a[aria-expanded="true"]::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 100%;
      transform: translateY(-23px) scale(1.1);
      transition: border-with 0.2s;
      @media (min-width: 1200px) {
        border: 1px ${secondaryBlue2} solid;
      }
    }

    &.large-sub-menu .dropdown-menu{
      .dropdown-container{
        @media screen and (max-width: 1375px) {
            flex-wrap: wrap;
        }
      }

      .dropdown-heading-container.row{
        flex-grow: 1;
      }
    }
  }

  .navbar-nav{
    max-width: 1200px;
  }

  .row{
    margin: 0;
  }
`
