import React from "react"

const Graph = ({ }) => {
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5999 2.00759C2.5999 1.40008 2.10742 0.907593 1.4999 0.907593C0.892389 0.907593 0.399902 1.40008 0.399902 2.00759V33.6277C0.399902 34.2629 0.9148 34.7777 1.5499 34.7777H38.8099C39.4174 34.7777 39.9099 34.2852 39.9099 33.6777C39.9099 33.0702 39.4174 32.5777 38.8099 32.5777H2.5999V2.00759ZM29.2266 5.25556L19.3266 5.25556C18.7191 5.25556 18.2266 5.74804 18.2266 6.35556C18.2266 6.96307 18.7191 7.45556 19.3266 7.45556L26.571 7.45556L10.1672 23.8594C9.73758 24.289 9.73758 24.9855 10.1672 25.415C10.5967 25.8446 11.2932 25.8446 11.7228 25.415L28.1266 9.01119V16.2556C28.1266 16.8631 28.6191 17.3556 29.2266 17.3556C29.8342 17.3556 30.3266 16.8631 30.3266 16.2556V6.35556C30.3266 5.74804 29.8342 5.25556 29.2266 5.25556Z" fill="url(#paint0_linear_24_186)"/>
      <defs>
      <linearGradient id="paint0_linear_24_186" x1="7" y1="36" x2="7" y2="1" gradientUnits="userSpaceOnUse">
      <stop stop-color="#41A4A0"/>
      <stop offset="1" stop-color="#2179B9"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Graph;