import styled from "styled-components"
import { colors } from "../../../../utils/styles"

export const StyledHowApexxCanHelpSection = styled.section`
  background: rgba(56, 101, 153, 0.16);
  color: ${colors.primaryBlue};
  .h1 {
    font-size: 2rem;
  }
  .rhs {
    border-left: solid 2px #d8d8d8;
    ul {
      list-style-type: none;
    }
  }
  .contact-sales {
    border: none;
  }
  @media (max-width: 992px) {
    background: ${colors.secondaryBlue4};
    .h1 {
      color: white;

      &.dark{
        color: ${colors.primaryBlue};
      }
    }
    .contact-sales-xs {
      width: 80%;
    }
  }

  &.white-bg{
    @media (max-width: 992px) {
      background-color: #F9F9F9;
    }
  }
`
